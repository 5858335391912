import { useEffect } from "react";

const useCustomStyle = (csdata) => {
  useEffect(() => {
    const name = document.querySelectorAll(".cv__template h1");
    const title = document.querySelectorAll(".cv__template h2");
    const subtitle_p = document.querySelectorAll(".cvt__subtitle p");
    const text = document.querySelectorAll(
      ".cv__template p, .cv__template span, .cv__template a"
    );
    const text_style = document.querySelectorAll(
      ".cv__template strong, .cv__template em, .cv__template u"
    );
    const li = document.querySelectorAll(".cv__template li");
    const svg = document.querySelectorAll(".cv__template svg");
    const theme_color = document.querySelectorAll(
      ".cvt__themecolor, .cv__template a"
    );
    theme_color.forEach((element) => {
      element.style.color = csdata.theme_color;
    });

    name.forEach((element) => {
      element.style.fontFamily = csdata.font_family_title;
    });
    title.forEach((element) => {
      element.style.fontSize = `${csdata.font_size_title}px`;
      element.style.fontFamily = csdata.font_family_title;
    });
    text.forEach((element) => {
      element.style.fontSize = `${csdata.font_size_text}px`;
      element.style.fontFamily = csdata.font_family_text;
    });
    text_style.forEach((element) => {
      element.style.fontFamily = csdata.font_family_text;
    });
    subtitle_p.forEach((element) => {
      element.style.fontSize = `${csdata.font_size_text + 1}px`;
    });
    li.forEach((element) => {
      element.style.fontSize = `${csdata.font_size_text}px`;
      element.style.marginBottom = `${csdata.font_size_text / 2 - 1}px`;
    });
    svg.forEach((element) => {
      element.style.fontSize = `${csdata.font_size_text + 4}px`;
    });
  });
};

export default useCustomStyle;
