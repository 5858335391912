import React from "react";
import "./Preview.less";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { Alert, Chip, FormControl, NativeSelect, Slider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { showDialog } from "../../redux/HandleDialog";
import DialogPro from "../DialogPro/DialogPro";
import { onTemplateKeyChange } from "../../redux/CVData";
import RecommendIcon from "@mui/icons-material/Recommend";
import useCustomStyle from "../../hooks/useCustomStyle";
import { debounce } from "../../utils/debounce";

const FONT_FAMILY = [
  "Arial",
  "Georgia",
  "Helvetica Neue",
  "Open Sans",
  "Serif",
  "Times New Roman",
];

const CustomStylePanel = ({ csdata }) => {
  const dispatch = useDispatch();

  const onChangeColor = debounce((value) => {
    dispatch(onTemplateKeyChange("theme_color", value));
  });

  return (
    <>
      <div className="cv__dialogpro-customstyle-item">
        <div className="cv__dialogpro-customstyle-title">Title Font Size</div>
        <Slider
          value={csdata.font_size_title}
          aria-label="Default"
          valueLabelDisplay="on"
          min={15}
          max={23}
          step={0.5}
          onChange={(e, value) =>
            dispatch(onTemplateKeyChange("font_size_title", value))
          }
          valueLabelFormat={(value) => `${value} px`}
        />
      </div>
      <div className="cv__dialogpro-customstyle-item">
        <div className="cv__dialogpro-customstyle-title">Text Font Size</div>
        <Slider
          value={csdata.font_size_text}
          aria-label="Default"
          valueLabelDisplay="on"
          min={11}
          max={16}
          step={0.5}
          onChange={(e, value) =>
            dispatch(onTemplateKeyChange("font_size_text", value))
          }
          valueLabelFormat={(value) => `${value} px`}
        />
      </div>
      <div className="cv__dialogpro-customstyle-item">
        <div className="cv__dialogpro-customstyle-title">Title Font Family</div>
        <FormControl fullWidth>
          <NativeSelect
            inputProps={{
              name: "font_family_title",
              id: "uncontrolled-native",
            }}
            value={csdata.font_family_title}
            onChange={(e) =>
              dispatch(onTemplateKeyChange("font_family_title", e.target.value))
            }
          >
            <option value="">System Default</option>
            {FONT_FAMILY.map((font) => (
              <option key={font} value={font}>
                {font}
              </option>
            ))}
          </NativeSelect>
        </FormControl>
      </div>

      <div className="cv__dialogpro-customstyle-item">
        <div className="cv__dialogpro-customstyle-title">Text Font Family</div>
        <FormControl fullWidth>
          <NativeSelect
            inputProps={{
              name: "font_family_text",
              id: "uncontrolled-native",
            }}
            value={csdata.font_family_text}
            onChange={(e) =>
              dispatch(onTemplateKeyChange("font_family_text", e.target.value))
            }
          >
            <option value="">System Default</option>
            {FONT_FAMILY.map((font) => (
              <option key={font} value={font}>
                {font}
              </option>
            ))}
          </NativeSelect>
        </FormControl>
      </div>
      <div className="cv__dialogpro-customstyle-item">
        <div className="cv__dialogpro-customstyle-title">Theme Color</div>
        <input
          type="color"
          id="cv__colorpicker"
          name="colorPicker"
          value={csdata.theme_color}
          onChange={(e) => onChangeColor(e.target.value)}
        />
      </div>
    </>
  );
};

const Preview = (props) => {
  const isShowDialog = useSelector((state) => state.handleDialog);
  const dispatch = useDispatch();

  // CV Preview Custom Style
  const csdata = useSelector((state) => state.cvData.template);

  useCustomStyle(csdata);

  return (
    <>
      <div
        id="cv__preview"
        className="cv__card-link"
        onClick={() => dispatch(showDialog())}
      >
        <div className="cv__template-thumbnail cv__template">
          {props.children}
        </div>

        <Chip
          className="cv__preview-sign"
          color="primary"
          icon={<ZoomInIcon />}
          label="PREVIEW"
        />
      </div>

      {/* Dialog - Template */}
      <DialogPro className="cv__dialogpro-template" isShowDialog={isShowDialog}>
        <div className="cv__template-large cv__template">{props.children}</div>

        <div className="cv__dialogpro-customstyle">
          <CustomStylePanel csdata={csdata} />
          <Alert
            variant="outlined"
            icon={<RecommendIcon fontSize="inherit" />}
            severity="warning"
            sx={{ marginBottom: "20px" }}
            className="cv__dialogpro-customstyle-suggestion"
          >
            Focus more on your CV content, not just the style.
          </Alert>
        </div>
      </DialogPro>
    </>
  );
};

export default Preview;
